import React from 'react'
import { Link } from 'gatsby'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
// import SelectLanguage from './SelectLanguage'

import Logo from '../../../../content/images/logo.png'

class Navi extends React.Component {
  render() {
    const { location } = this.props

    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        // variant="dark"
        className="static-top bg-white border-bottom shadow-sm"
      >
        <Navbar.Brand href="/am/">
          <img
            src={Logo}
            alt="Medemer"
            width="150"
            className="img-responsive mb-0"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Nav.Link
              href="/am/"
              className={
                location.pathname === '/am/' ? 'nav-link active' : 'nav-link'
              }
            >
              ቤት
            </Nav.Link>
            <Nav.Link
              href="/am/about/"
              className={
                location.pathname === '/am/about/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              ስለ ጠቅላይ ሚኒስትር
            </Nav.Link>
            {/* <Nav.Link
              href="/am/what-is-medemer/"
              className={
                location.pathname === '/am/what-is-medemer/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              መደመር ምን ማለት ነው?
            </Nav.Link> */}
            <Nav.Link
              href="/am/book/"
              className={
                location.pathname === '/am/book/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              መጽሐፍ
            </Nav.Link>
            <Nav.Link
              href="/am/social/"
              className={
                location.pathname === '/am/social/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              #መደመር
            </Nav.Link>
            <Nav.Link
              href="/am/send-message/"
              className={
                location.pathname === '/am/send-message/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              መልእክት ይላኩ
            </Nav.Link>
            <Nav.Link
              href="/"
              className={`btn btn-outline-secondary ${
                location.pathname === '/' ? 'nav-link active' : 'nav-link'
              }`}
            >
              English
            </Nav.Link>
            {/* <SelectLanguage langs={langs} /> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Navi
