import React from 'react'
import emergence from 'emergence.js'

import Navi from 'components/navi'
import AmNavi from 'components/navi/am'
import Footer from 'components/footer'
import { siteMetadata } from '../../../gatsby-config'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'

class Layout extends React.Component {
  componentDidMount() {
    emergence.init()
  }

  componentDidUpdate() {
    emergence.init()
  }

  render() {
    const { children, location } = this.props

    const amharicPathname =
      location && location.pathname && location.pathname.search('/am/')

    return (
      <div>
        {amharicPathname ? (
          <Navi title={siteMetadata.title} {...this.props} />
        ) : (
          <AmNavi title={siteMetadata.title} {...this.props} />
        )}

        {children}

        <Footer title={siteMetadata.title} author={siteMetadata.author} />
      </div>
    )
  }
}

export default Layout
