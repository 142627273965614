module.exports = {
  siteMetadata: {
    title: 'Medemer',
    description:
      'Medemer is a philosophy adopted by the Prime Minister of Ethiopia, Dr. Abiy Ahmed. Contextually, the term conveys the ideas of synergy, the principles found with inclusive practices and a collectivist approach.',
    siteUrl: process.env.GATSBY_CURRENT_DOMAIN || 'https://medemer.et',
    author: 'Medemer',
    twitter: 'MedemerNow',
    adsense: '',
    logo: `${__dirname}/content/images/logo.png`,
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/posts/`,
        name: 'posts',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/images/`,
        name: 'images',
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Medemer',
        short_name: 'Medemer',
        description:
          'Medemer is a philosophy adopted by the Prime Minister of Ethiopia, Dr. Abiy Ahmed. Contextually, the term conveys the ideas of synergy, the principles found with inclusive practices and a collectivist approach.',
        homepage_url: process.env.GATSBY_CURRENT_DOMAIN || 'https://medemer.et',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#7fb136',
        display: 'standalone',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-google-analytics',
      options: {
        trackingId: 'UA-147970486-1',
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    {
      resolve: 'gatsby-source-firestore',
      options: {
        credential: require('./credentials.json'),
        types: [
          {
            type: 'Social',
            collection: 'social',
            // map: doc => ({
            //   fullName: doc.fullName,
            //   city: doc.city,
            //   country: doc.country,
            //   description: doc.description,
            //   approved: doc.approved,
            // }),
          },
        ],
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    'gatsby-transformer-sharp',
  ],
}
