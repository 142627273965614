import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Medemer from 'components/medemer'

import PMPortrait from '../../content/images/pm-abiy-4.jpg'

class About extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="About" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">About</h1>
              <p className="lead text-muted">
                H.E. Prime Minister Abiy Ahmed (PhD)
              </p>
            </div>
          </section>

          <div className="container marketing">
            <div className="row featurette">
              <div className="col-md-7 order-md-2">
                <h2>
                  Prime Minister of the Federal Democratic Republic of Ethiopia
                </h2>
                <p>
                  H.E. Abiy Ahmed Ali (PhD) is the fourth Prime Minister of the
                  Federal Democratic Republic of Ethiopia. He was born on August
                  15, 1976 in the small town of Beshasha, located near Agaro,
                  Oromia. Raised by a Muslim father and a Christian mother, the
                  values of tolerance and understanding across divides were
                  instilled in him at an early age.
                </p>
                <p>
                  H.E. Abiy Ahmed was sworn in as the Prime Minister of the
                  Federal Democratic Republic of Ethiopia on April 2, 2018.
                  Inheriting a divided society, in all his speeches and actions
                  Prime Minister Abiy Ahmed has made national unity, peaceful
                  co-existence, shared growth and regional integration a central
                  theme of his administration. His inaugural speech inspired
                  hope and renewed collective sense of purpose in Ethiopia.
                </p>
                <p>
                  He has rich and wide-ranging experiences serving government in
                  different roles, offering a unique blend of leadership, vision
                  and knowledge in professional and organization skills.
                  Determined to resist the oppression of the Ethiopian people,
                  as a young man, H.E. Abiy Ahmed first joined the armed
                  struggle against the Dergue Socialist regime. While serving at
                  the Ethiopian National Defense Force, he rose to the rank of
                  Lieutenant Colonel. Among his various roles in government, he
                  has served as the Head of the OPDO Secretariat; Vice President
                  of the Oromia Regional Government; Minister, Ministry of
                  Science and Technology; founding Director of the Information
                  Network Security Agency and founder and Head of the government
                  research institute, Science and Technology Information Center.
                </p>
                <p>
                  Since being sworn into office as Prime Minister, he broke with
                  tradition – launching deeper political and economic reforms
                  that swept across the country. An original change agent and a
                  blunt reformer, breaking the walls of division and building
                  bridges of reconciliation and peace constituted the defining
                  themes of his reform efforts. Under his leadership, thousands
                  of prisoners were released; open discussions and consultations
                  held with opposition political parties – some of whom were
                  previously dubbed as "terrorists"; a historic rapprochement
                  and peace agreement was concluded with neighboring Eritrea; a
                  transformational agenda for legal and justice sector reform
                  has been set in motion; and some of the largest public
                  enterprises are to be partially privatized as part of a
                  broader market liberalization and economic reform agenda.
                </p>
                <p>
                  Prime Minister Abiy Ahmed holds an MA in Transformational
                  Leadership, a Master's in Business Administration and a PhD
                  from the Institute for Peace and Security Studies of Addis
                  Ababa University. He and his wife—First Lady Zinash
                  Tayachew--are proud parents of three daughters and a recently
                  adopted son.
                </p>
                <p>
                  On 11 October 2019, Dr. Abiy Ahmed was awarded the 2019 Nobel
                  Peace Prize for his work in ending the 20-year stalemate
                  between Ethiopia and Eritrea.
                </p>
                <div>
                  <br />
                </div>
              </div>
              <div className="col-md-5 order-md-1">
                <img src={PMPortrait} width="100%" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
        <Medemer />
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query AboutPageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
