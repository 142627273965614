import React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa'

import LogoDark from '../../../content/images/logo-grey.png'
import facebookIcon from '../../../content/images/icon-facebook.jpg'
import twitterIcon from '../../../content/images/icon-twitter.jpg'
import youtubeIcon from '../../../content/images/icon-youtube.jpg'
import './style.scss'

const Footer = ({ author, title }) => (
  <section className="footer small" style={{ background: '#E9E9E9' }}>
    <div className="container">
      <div className="row">
        <div className="col-3 col-md">
          <img src={LogoDark} width="200" />
        </div>
        <div className="col-12 col-md text-right">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="https://www.facebook.com/MedemerNow/" target="_blank">
                <FaFacebook size="30" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.twitter.com/MedemerNow/" target="_blank">
                <FaTwitter size="30" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.youtube.com/channel/UCeEc9aNIEIZjst_xmOaoLhw"
                target="_blank"
              >
                <FaYoutube size="30" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md text-center">
          <p>
            <a href="/terms">Terms of Service</a> &middot;{' '}
            <a href="/privacy-policy">Privacy Policy</a>
          </p>
          <p>Copyright &copy; 2019 Medemer. All rights reserved.</p>
        </div>
      </div>
    </div>
  </section>
)

export default Footer
