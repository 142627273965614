import React from 'react'
import { Link } from 'gatsby'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
// import SelectLanguage from './SelectLanguage'

import Logo from '../../../content/images/logo.png'

class Navi extends React.Component {
  render() {
    const { location } = this.props

    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        // variant="dark"
        className="static-top bg-white border-bottom shadow-sm"
      >
        <Navbar.Brand href="/">
          <img
            src={Logo}
            alt="Medemer"
            width="150"
            className="img-responsive mb-0"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Nav.Link
              href="/"
              className={
                location.pathname === '/' ? 'nav-link active' : 'nav-link'
              }
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="/about/"
              className={
                location.pathname === '/about/' ? 'nav-link active' : 'nav-link'
              }
            >
              About
            </Nav.Link>
            <Nav.Link
              href="/what-is-medemer/"
              className={
                location.pathname === '/what-is-medemer/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              What is Medemer?
            </Nav.Link>
            <Nav.Link
              href="/book/"
              className={
                location.pathname === '/book/' ? 'nav-link active' : 'nav-link'
              }
            >
              Book
            </Nav.Link>
            <Nav.Link
              href="/social/"
              className={
                location.pathname === '/social/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              #Medemer
            </Nav.Link>
            {/* <Nav.Link
              href="https://medemernow.com/"
              className={
                location.pathname === 'https://medemernow.com/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              Shop
            </Nav.Link> */}
            <Nav.Link
              href="/send-message/"
              className={
                location.pathname === '/send-message/'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              Send a Message
            </Nav.Link>
            <Nav.Link
              href="/am/"
              className={`btn btn-outline-secondary ${
                location.pathname === '/am/' ? 'nav-link active' : 'nav-link'
              }`}
            >
              በአማርኛ
            </Nav.Link>
            {/* <SelectLanguage langs={langs} /> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Navi
